<template>
    <div class="post offers btm-menu show-bulletpoints-normal">
        <breadcrumb :background="true" :content="breadcrumb"></breadcrumb>

        <div :class="['profile', typeClass]">
            <div class="container-fluid">
                <div class="profile-main">
                    <div v-if="icon!==null && !$isMobileScreen" :alt="subCategory" class="cat-icon-container mr-5"><img class="cat-icon" :src="icon">
                    </div>

                    <div class="profile-main__info">
                        <p v-if="!$isMobileScreen" class="offer-by mb-2">Bir teklif <router-link :to="{ name: 'OrteDetail', params: {id: institutionId } }">{{institutionTitle}}</router-link></p>
                        <div class="bg-container" v-else="">
                            <div class="cat-icon-container-mobile">
                                <img class="bg-img" :src="icon">
                            </div>
                            <div>
                                <p class="offer-by">Bir teklif<br> <router-link :to="{ name: 'OrteDetail', params: {id: institutionId } }">{{institutionTitle}}</router-link></p>
                            </div>
                        </div>
                        <div class="profile-main__info-title title">
                            <h3>{{ title }}</h3>
                            <share-button class="share-btn"></share-button>
                        </div>

                        <info-block target="desktop" :content="content" :infos="infoBlockInfos"></info-block>

                    </div>
                </div>
            </div>
        </div>

        <div v-if="$isMobileScreen" class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <info-block class="col-12 d-lg-none" target="mobile" :content="content" :infos="infoBlockInfos"></info-block>
                            <div class="col-12 mt-4">
                                <div class="about-content d-lg-none">
                                    <h5 class="post-heading">Ne yapılıyor?</h5>
                                    <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                    <p v-else="" v-html="description">tttt</p>
                                    <button v-if="shortDescription !== ''" @click="showMoreDescription = !showMoreDescription" class="btn btn-block mt10 btn-link text-primary show-more">
                                        {{ showMoreDescription ? "Mehr anzeigen" : "Weniger anzeigen" }}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="schlagworte!==null || !$isMobileScreen" class="section about">
         <div class="container-fluid">
            <div class="row"> 
                <div class="col-12 col-lg-6" v-if="!$isMobileScreen">
                    <h5 class="post-heading">Ne yapılıyor?</h5>
                    <p v-html="description"></p>
                </div>
                <div v-if="schlagworte!==null" class="col-12 col-lg-6">
                    <div class="about-chips">
                        <h5 class="post-heading">Kısaca özetlemek gerekirse</h5>
                        <h6 class="chip" v-for="(item, i) in schlagworte" :key="i">{{ item }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section pt-lg-0">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-lg-6">
                    <detail-map v-if="content !== null && address !== ''" :content="content" :addressObj="address"></detail-map>
                    <connection-card v-if="connectionAddress!==''" :address="connectionAddress"></connection-card>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="about-content anmeldung d-none d-lg-block" v-if="anmeldung!==''">
                        <h5 class="post-heading">Nasıl kayıt olabilirim?</h5>
                        <p v-html="anmeldung"></p>
                    </div>
                    <contact-block class="mt40 mt-lg-0" :contentId="content.id" :contact="contactInfo" v-if="contactInfo!=null && content!==null"></contact-block>
                </div>
            </div>
        </div>
    </div>

    <div class="section pt-lg-0 pb-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h5 class="post-heading">Benzer teklifler</h5>
                    <div class="row mobile--scrolling pt-1" v-if="similar!==null">
                        <angebot-card class="mr-2 ml-2 similar-content" v-for="(item, index) in similar" :key="index" :item="item"></angebot-card>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <collection-block v-if="content!==null" :content="content"></collection-block>

</div>
</template>
<script>
import Resource from '@/api/resource';
import { getContentsOfType } from '@/api/content';
import { getFieldValues, getFirstFieldValue, showLoader, hideLoader, isset, json_decode, getFlyerLink } from '@/utils/helpers';
import {getSubCategory, getCategoryIcon } from '@/utils/lupe-helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
const contentResource = new Resource('contents');

export default {
    name: 'AngeboteDetail',
    mixins: [screenSizeMixin],
    components: {
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        ConnectionCard: () => import('@/components/cards/Connection.vue'),        
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
        DetailMap: () => import('@/components/controls/DetailMap.vue'),
        ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
    },
    data() {
        return {
            content: null,
            showMoreDescription: false,
            showMoreInfo: true,
            dataSet: {
              total: 0,
              data: null,
              meta:null,
              query: {
                page:1,
                limit: 5,
                selectedAppId: this.$appId,
                keyword: null,
                selectRandomly: true,
                selectedContentTypeId: this.$offerId,
                view_status: 1,
                sort: {
                  prop: '',
                  order: 'asc'
              },
          },
      },
  }
},
watch: {
  id(newId, oldId) {
    if (newId!=oldId) {
      this.getContent();
      this.isActiveLocation=true;
      this.$scrollTo();
  }
},
},
created() {
  this.getContent();
  this.isActiveLocation=true;
},
computed: {
    similar(){
        if(this.dataSet.data != null && this.dataSet.data.length>0){
            var data = [];
            for(var i = 0; i < this.dataSet.data.length; i++){
                var bla = this.dataSet.data[i];
                /*check if the given content is this content - we don't want the same content as a recommendation*/
                if(bla.id!==this.content.id){
                    data.push(bla);
                }
                /*we only need four contents*/
                if(data.length == 4){
                    return data;
                }
            }
            /*hard-coded Langcode*/
            return data.length>0?data:null;
        }
        return null;
    },
    icon(){
        switch(this.category) {
        case 'Bildung':
            return '/assets/icons/kategorien/bildung.png';
            break;
        case 'Freizeit':
            return '/assets/icons/kategorien/freizeit.png';
            break;
        case 'Ehrenamt & Engagement':
            return '/assets/icons/kategorien/ehrenamt.png';
            break;
        case 'Beratung':
            return '/assets/icons/kategorien/beratung.png';
            break;
        case 'Sprache':
            return '/assets/icons/kategorien/sprache.png';
            break;
        case 'Wohnen':
            return '/assets/icons/kategorien/wohnen.png';
            break;
        default:
            return '/assets/RefugeeCompass_bg.png';        
        } 
    },
    backgroundImg() {
        return 'backgroundImage: url("'+this.icon+'")';  
    },
    logo(){
        if(this.item!==null){
            var logo = getFirstFieldValue(this.content,'logo');
            if(logo!==null){
                return this.$backendUrl+logo;
            }
        }
        return "";
    },
    connectionAddress(){
        if(this.address!=''){
            return this.address.street+', '+this.address.zipcode+' '+this.address.city;
        }
        return '';
    },
    typeClass(){
        let cat = this.category + '-style';
        return cat!==null?cat.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
    },
    id() {
        return this.$route.params.id;
    },
    title(){
        if(this.content!=null){
          return this.content.title;
      }
      return "";
  },
  institutionTitle(){
    if(this.content!=null){
        return this.content.institution_title;
    }
    return '';
},
institutionId(){
    if(this.content!=null){
        return this.content.institution_id;
    }
    return '';
},
description(){
    var desc = getFieldValues(this.content,'description');
    return desc!=null?desc:'';
},
anmeldung(){
    var value = getFieldValues(this.content,'anmeldung');
    return value!=null?value:'';
},
linkZurAnmeldung(){
    var value = getFieldValues(this.content,'link_zur_anmeldung');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.content,'flyer_hochladen'),this.$backendUrl);
},
showShareBtnInTitle(){
    return this.$isMobileScreen;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;

        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
infoBlockInfos(){
    var object = {};
    object.address = this.address;
    object.costs = getFieldValues(this.content,'kosten');
    object.appointmentType = getFieldValues(this.content,'terminart');
    object.appointments = getFieldValues(this.content,'appointments');

    object.linkTo = {
        url: getFieldValues(this.content,'url'),
        facebook: getFieldValues(this.content,'facebook'),
        instagram: getFieldValues(this.content,'instagram'),
        twitter: getFieldValues(this.content,'twitter'),
        youtube: getFieldValues(this.content,'youtube'),
    }
    return object;
},
address(){
    var address = getFieldValues(this.content,'adresse');
    return address!==null?address:'';
},
breadcrumb() {
    if(isset(this.category) && isset(this.subCategory)){
        return "Angebote" + ' | ' + this.category + " | " + this.subCategory; 
    }
    return "Angebote";
},
category(){
    var value = getFieldValues(this.content,'kielrefugeecompass');
    if(value!=null){
        if(Array.isArray(value) && value.length>0){
            return value[0];
        }
        return value;
    }
    return null;
},
subCategory(){
    if(isset(this.category)){
        return getSubCategory(this.content,this.category);
    }
    return null;
},
contactInfo(){
    var contact = getFieldValues(this.content,'kontaktinformationen');
    return contact!==null?contact:null;
},
schlagworte(){
    var value = getFieldValues(this.content,'kielrefugeecompass_-_schlagwrter');
    if(value!=null){
        if(!Array.isArray(value)){
            var temp = value;
            value = [temp];
        }
        return value;
    }
    return null;
},
weitereInfos(){
    var value = getFieldValues(this.content,'weitere_infos');
    return value!=null?value:'';
},
},
methods:{
   getFieldValues, 
   getFirstFieldValue,
   showLoader, 
   hideLoader, 
   getContent() {
      this.loader = this.showLoader(this.loader);
      contentResource.get(this.id)
      .then(response => {
        this.content = response.data;
        this.dataSet.query.keyword = this.category;
        this.getSimilarContents();
    })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
  },
  async getSimilarContents() {
    const { limit, page } = this.dataSet.query;
    const { data, meta } = await getContentsOfType(this.$offerId,this.dataSet.query, this.$store.getters.getLanguage);
    this.dataSet.data = data;
    this.dataSet.data.forEach((element, index) => {
      element['index'] = (page - 1) * limit + index + 1;
  });
    this.dataSet.meta = meta;
    this.dataSet.total = meta.total;
},
}
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.offers{
    @media (min-width: 992px) {
        margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});
    }

    .mobile--scrolling{
      margin-left: 0px;
      padding-right: 15px;
  }

  .profile{

    &-main {
        justify-content: space-between;
    }

    .container-fluid{
        @media (max-width: 991px) {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .profile-main__info {
            max-width: none;

            @media (max-width: 1360px) {
              min-width: 540px;
              margin-right: 60px;
          }

          @media (max-width: 991px) {
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        @media (max-width: 555px) {
            min-width: auto;
        }

        .info-list {
            margin-right: 20px;
        }

        h5 {
            @media (max-width: 991px) {
                margin-bottom: 0px;
            }
        }

        h5, h3 {
            color: $text-color;
        }
    }
}


}
}

.cat-icon-container-mobile {
    border-radius: 50%;
    padding: 10px;
    background-color: #FFFF;
    margin-right: 10px;
}

.Beratung-style {
    background-color: #FCCC9F !important;
    .cat-icon-container-mobile {
       border: 3px solid #FA993F;
   }
}

.Sprache-style {
    background-color: #F6A7AB !important;
    .cat-icon-container-mobile {
       border: 3px solid #ED4F58;
   }
}

.Bildung-style {
    background-color: #FDEBB6 !important;
    .cat-icon-container-mobile {
     border: 3px solid #FCD770;
 }
}

.Wohnen-style {
    background-color: #DED5F3 !important;
    .cat-icon-container-mobile {
     border: 3px solid #DED5F3;
 }
}

.Freizeit-style {
    background-color: #C7E4FF !important;
    .cat-icon-container-mobile {
       border: 3px solid #90C9FF;
   }
}

.EhrenamtEngagement-style {
    background-color: #A4E6C7 !important;
    .cat-icon-container-mobile {
     border: 3px solid #46CC8D;
 }
}

.share-btn {
    span {
        color: $text-color !important;
        border: 1px solid $white-color;
        border-radius: 50%;
        background-color: $white-color;

        @media(max-width:991px){
            padding: 5px !important;
            background: rgba(255,255,255,.7);
        }

        &:hover {
            background-color: none;
        }
    }
}

.title {
    @media(min-width:992px){
        justify-content: normal !important;
    }

    h3 {
        margin-right: 15px;
    }
}

.anmeldung {
    margin-bottom: 50px;
    padding: 0 40px;
}

.about-chips {
    margin-top: 0 !important;
    padding-left: 40px;

    @media (max-width: 991px) {
       padding-top: 30px;
       padding-left: 0;
   }
}

.offers #theMap, .offers .map-container {
    height: 426px !important;
}

.offer-by {
    color: $dark-color !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    a {
        color: $dark-color !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-decoration: underline;
    }

    a:hover {
        color: #157FAC !important;
    }
}

.show-bulletpoints-normal ul {
    @media (max-width: 991px) {
        padding-left: 0;
    }
}

.post-heading {
    @media (max-width: 991px) {
        font-size: 24px;
    }
}

.card {
    margin-top: 0 !important;

    @media (max-width: 991px) {
        min-width: 300px;
    }

    .card-head {
        overflow:hidden;
    }
}

.contact-block {
    @media(max-width:991px){
        padding: 0 !important;
    }
}

.connection-comp {
    margin-top: 30px !important;

    @media (max-width: 991px) {
        margin-top: 0 !important;
    }
}

.cat-icon-container {
    border: 5px solid grey;
    border-radius: 50%;
    padding: 20px;
    background-color: #FFF;
}

.cat-icon {
    width: 122px;
    height: 122px;
}
.bg-container{
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .bg-img {
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
    }
}

.show-more:hover, .show-more:active, .show-more:focus {
    background: none !important;
}

.info {
    @media(max-width:991px){
        margin-top: 0 !important;
        margin-bottom: 10px;
    }
}

.url-link {
    color: $primary !important;
}

.similar-content .card {
    @media(max-width: 450px){
        width: 90vw !important;
    }

    @media(max-width: 350px){
        width: 100% !important;
    }
}

body[dir="rtl"] {
    .cat-icon-container-mobile {
        margin-right: 0;
        margin-left: 10px;
    }
}

</style>
